<template>
  <div>
    <input type="text" v-model="newEmployee" placeholder="Name" />
    <button class="btn btn-success" @click="addEmployee()">Add Employee</button>
    <transition-group tag="ul" name="slide-up" appear class="list-group list-group-flush">
      <li class="list-group-item" v-for="emp in employees" :key="emp">{{ emp }}</li>
    </transition-group>
  </div>
</template>

<script>
export default {
  data () {
    return {
      newEmployee: '',
      employees: ['John Borg', 'Luke Ellul', 'David Sacco']
    }
  },
  methods: {
    addEmployee () {
      this.employees.push(this.newEmployee)
    }
  }
}
</script>

<style scoped>

.slide-up-enter-from{
  transform: translateY(10px);
  opacity: 0;
}

.slide-up-enter-active{
  transition: all 1s ease;
}

</style>
