<template>
  <div class="home">
    <div class="card" style="width: 24rem;" id="card-container-custom">
      <div class="card-header">
        Tasks
      </div>
      <ul class="list-group list-group-flush">
        <task-card v-for="task in tasks" :key="task.id" :task="task"></task-card>
      </ul>

      <!-- adding Previous and Next Page links -->
      <div class="pagination">
        <router-link id="prev"
          :to="{ name: 'TaskList', query: { page: page - 1}}" v-if="page != 1"> &lt; Prev
        </router-link>

        <router-link id="next"
          :to="{ name: 'TaskList', query: { page: page + 1}}" v-if="hasNextPage"> Next &gt;
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import TaskCard from '@/components/TaskCard.vue'
import TasksService from '@/services/TasksService.js'
import { watchEffect } from 'vue'

export default {
  name: 'TaskList',
  props: ['page'],
  components: {
    TaskCard
  },
  data () {
    return {
      tasks: null,
      totalTasks: 0
    }
  },
  computed: {
    hasNextPage () {
      // Math.ceil - rounds up the value to the nearest larger number
      // divide by 2 since 2 tasks per page are being shown
      var totalPages = Math.ceil(this.totalTasks / 2)
      // if this page is not the last page
      return this.page < totalPages
    }
  },
  created () {
    watchEffect(() => {
      TasksService.getTasks(2, this.page)
        .then(response => {
          this.tasks = response.data
          this.totalTasks = response.headers['x-total-count']
        })
        .catch(error => {
          console.log('ERRORS' + error)
        })
    })
  }
}
</script>

<style scoped>
#card-container-custom{
  margin: auto;
}

.pagination{
  display: flex;
  width: 382px;
}

.pagination a{
  flex: 1;
  text-decoration: none;
  color: black;
}

#prev{
  text-align: left;
}

#next{
  text-align: right;
}
</style>
