<template>
    <button class="btn btn-danger" @click=deleteTask() data-toggle="modal" data-target="#taskModal">Delete Task</button>
</template>

<script>
export default {
  props: ['task'],
  // make GStore object available inside this component
  inject: ['GStore'],
  methods: {
    deleteTask () {
      // After deletion is successful the push method will redirect user
      console.log('Task deleted')
      // flash message code goes here
      this.GStore.flashMessage = this.task.title + ' was deleted'
      // reset flash message after 3 seconds

      /*
      setTimeout(() => {
        this.GStore.flashMessage = ''
      }, 3000)
      */
      this.$router.push({
        name: 'TaskDetails'
      })
    }
  }
}
</script>

<style scoped>

    .task-details{
        border: 1px solid black;
        width: 40%;
        margin: 15px auto;
        padding: 10px;
    }

</style>
