import { createRouter, createWebHistory } from 'vue-router'
import TaskList from '../views/TaskList.vue'
import TaskDetails from '../task/Details.vue'
import TaskDelete from '../task/Delete.vue'
import TaskEdit from '../task/Edit.vue'
import TaskLayout from '../task/Layout.vue'
import About from '../views/About.vue'
import Employee from '../views/Employee.vue'
import NotFoundComponent from '../components/NotFoundComponent.vue'

const routes = [
  {
    path: '/',
    name: 'TaskList',
    component: TaskList,
    props: route => ({ page: parseInt(route.query.page) || 1 })
  },
  {
    path: '/about',
    name: 'About',
    component: About
  },
  {
    path: '/employees',
    name: 'Employee',
    component: Employee
  },
  {
    path: '/tasks/:id',
    name: 'TaskLayout',
    props: true,
    component: TaskLayout,
    children: [
      { // nest child components
        path: '', // this will be loaded at the root path of the parent
        name: 'TaskDetails',
        component: TaskDetails
      },
      {
        path: 'edit', // this will be added to the parent path
        name: 'TaskEdit',
        component: TaskEdit
      },
      {
        path: 'delete', // this will be added to the parent path
        name: 'TaskDelete',
        component: TaskDelete
      }
    ]
  },
  {
    path: '/:catchAll(.*)',
    component: NotFoundComponent
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
