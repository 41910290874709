<template>

  <transition name="fade">
    <div id="flashMessage" v-if="GStore.flashMessage">
      {{ GStore.flashMessage }}
      <button @click="GStore.flashMessage = ''" class="btn btn-secondary">Close</button>
    </div>
  </transition>

  <!--
  <div class="modal" id="taskModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">{{ GStore.flashMessage }}</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
        </div>
      </div>
    </div>
  </div>
  -->

  <div id="nav">
    <router-link to="/">TaskList</router-link> |
    <router-link to="/about">About</router-link> |
    <router-link to="/employees">Employees</router-link>
  </div>

  <router-view v-slot="{ Component }">
    <transition name="slide-fade" mode="out-in">
      <component :is="Component" />
    </transition>
  </router-view>
</template>

<script>
export default {
  inject: ['GStore']
}
</script>

<style>

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease-out;
}

.slide-fade-leave-to {
  transform: translateX(10px);
  opacity: 0;
}

.slide-fade-enter-from{
  transform: translateX(-10px);
  opacity: 0;
}

.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all 0.3s ease;
}

@keyframes bluefade {
  from {
    background: lightblue;
  }
  to {
    background: transparent;
  }
}

#flashMessage{
  animation-name: bluefade;
  animation-duration: 3s;
}

#app {
  width:100%;
  margin: auto;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
