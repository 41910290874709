<template>
  <router-link class="task-link" :to="{name: 'TaskDetails', params:{id: task.id}}">
    <li class="list-group-item"><b>{{task.title}}</b> on the {{task.date}} @ {{task.time}}</li>
  </router-link>
</template>

<script>
export default {
  name: 'Task Card',
  props: {
    task: Object
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

  .task-link{
    text-decoration: none;
    color: #2c3e50
  }

  .task-link:hover{
    transform: scale(1.02);
    box-shadow: 0px 0px 8px 10px #00000008;
  }

</style>
