<template>
<div>
  <p>{{task.time}} on {{task.date}} @ {{task.location}}</p>
  <p>{{task.description}}</p>
</div>
</template>

<script>
export default {
  props: ['task']
}
</script>

<style scoped>

    .task-details{
        border: 1px solid black;
        width: 40%;
        margin: 15px auto;
        padding: 10px;
    }

</style>
